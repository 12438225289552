/* styles.css */
.table-responsive {
  overflow-x: auto;
}

.table th:first-child,
.table td:first-child {
  font-weight: bold;
  background-color: #f0f0f0;
  /* Set the background color for the first column (adjust as needed) */
}

.table th,
.table td {
  text-align: center;
  white-space: nowrap;
}

div {
  width: 95%;
  margin: 0 auto;
}

.result {
  text-indent: 5%;
  color: red;
  font-weight: 500;
  position: relative;
  margin-top: 2%;
}

.logo {
  /* Add your desired styles for the logo */
  width: 100px;
  /* Adjust the width as needed */
  height: 74;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Maintain aspect ratio */
}

header {
  height: 150px;
  position: relative;
}

footer {
  height: 80px;
  position: relative;
  margin-top: 0%;
  width: 100%;
  text-align: center;
  font-size: medium;
  font-weight: 500;
}

#formId {
  align-items: center;
}

#score_button {
  align-items: center;
}

#text_ketqua {
  position: relative;
  top: 10px;
  text-align: left;
  font-size: larger;
}

#table_ketqua {
  position: relative;
  top: 20px;
}

#rank_khoi {
  position: relative;
  margin-top: 10px
}

#text_header {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: larger;
}

a {
  text-decoration: "none";
}

sup {
  vertical-align: super;
  /* Sử dụng thuộc tính vertical-align để đặt số mũ */
  font-size: 0.7em;
}

.info {
  text-decoration: none;
}

#compare_score {
  text-decoration: none;
  font-weight: 500;
}

#container_compare {
  position: relative;
  top: 0%;
  text-align: center;
}

#noti {
  position: relative;
  text-align: center;
  margin-top: 2%;
}

@media screen and (max-width: 820px) {
  header {
    height: 150px;
    width: 80%;
    position: relative;
    margin: 0 auto;
  }

  #text_header {
    position: relative;
    top: 75%;
    text-align: center;
    font-weight: 500;
    font-size: 19px;
  }

  footer {
    height: 80px;
    position: relative;
    margin-bottom: 10%;
    margin-top: 10%;
    width: 100%;
    text-align: center;
    font-size: 10pt;
    font-weight: 500;
  }

  .result {
    text-indent: 5%;
    color: red;
    font-weight: 500;
    position: relative;
    margin-top: 12%;
  }

  .enter_sbd {
    position: relative;
    margin-top: 10%;
  }
}